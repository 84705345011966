.dropdown-toggle {
  z-index: 1001;
  background-color: $gray-100;
  transition: 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  &:after {
    display: none;
  }
}
.dropdown-menu {
  transform: translate3d(0,-115%,0)!important;
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0s 0.3s;
  display: block;
  opacity: 0;
  background-clip: border-box;
  &.show {
    transform: translate3d(0, 9px, 0) !important;
    opacity: 1;
    transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
}