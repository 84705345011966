.download {
  img {
    margin-bottom: 1.25rem;
    border-bottom: 1px solid $light-blue;
  }
  .dropdown-link {
    position: relative;
    cursor: pointer;
    p {
      padding-left: 30px;
      color: $primary;
    }
  }
  .opener {
    transition: height .25s ease;
    overflow: hidden;
    padding-left: 30px;
    &:not(.open) {
      display: none;
    }
  }
  .box {
    margin-bottom: 30px;
    border: 1px solid $light-blue;
    border-radius: $border-radius;
  }
  .wrapper {
    padding-left: 15px;
    padding-right: 15px;
    p.equalize {
      @include media-breakpoint-down(xs) {
        height: auto !important;
      }
    }
  }
}