main {
  position: relative;
}

img {
  width: 100%;
  object-fit: cover;
  display: block;
}

hr {
  border-color: $light-blue;
}