section.img {
  padding: 0;
  @include media-breakpoint-down(md) {
    .container {
      padding: 0;
      margin: 0;
      max-width: none;
      .row {
        margin: 0;
        .col-12 {
          padding: 0;
        }
      }
    }
  }
}