footer {
  padding-top: $section-padding;
  padding-bottom: $section-padding;
  background-color: #535f6b;
  nav.footer {
    padding-bottom: 60px;
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
  ul {
    list-style: none;
    padding: 0;
    li {
      color: $white;
      padding-top: 5px;
      a {
        font-size: 18px;
        color: $white;
        &:hover {
          color: $white;
        }
      }
    }
  }
  .socialmedia {
    display: flex;
    justify-content: space-between;
    a {
      display: block;
      color: $white;
      font-size: 50px;
      &:hover {
        color: $white;
        text-decoration: none;
      }
      &:before {
        margin: 0;
      }
    }
    @include media-breakpoint-down(sm) {
      padding-bottom: 15px;
      border-bottom: 1px solid #92b1c4;
    }
  }
  .copyright {
    white-space: nowrap;
    color: $white;
    @include media-breakpoint-down(sm) {
      padding-top: 15px;
    }
    .link-red {
      color: #AE0035 !important;
    }
    p {
      & > * {
        padding: 3px 0px;
      }
      text-align: right;
      position: relative;
      padding-right: 90px;
      @include media-breakpoint-down(sm) {
        text-align: left;
      }
      i {
        position: absolute;
        right: 0;
        top: -10px;
        font-size: 70px;
        &:before {
          margin: 0;
        }
        @include media-breakpoint-down(sm) {
          font-size: 49px;
          top: -14px;
        }
      }
    }
    p.link-paragraph {
      padding-left: 40px;
      @include media-breakpoint-down(sm) {
        padding-left: 0px;
      }
    }
    a {
      display: block;
      color: $white;
      &:hover {
        color: $white;
      }
    }
  }
}
