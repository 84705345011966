.modules-grid {
  @include media-breakpoint-down(xs) {
    .row {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
    .col-12 {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
}

.hover-box {
  ul {
    color: #fff;
    padding-right: 20%;
  }
  img {
    width: 545px;
    height: 284px;
    object-fit: cover;
  }
  &.col-12 {
    padding: 15px;
  }
  .col-12 {
    padding: 15px;
  }
  .box {
    position: relative;
    overflow: hidden;
    border: 1px solid $gray-600;
    border-radius: $border-radius;
    .link {
      position: absolute;
      width: 100%;
      height: 100%;
      display: block;
      text-indent: 100%;
      white-space: nowrap;
      overflow: hidden;
      z-index: 11;
    }
    &:hover {
      .hover {
        transform: translate(0, 8%);
        @include media-breakpoint-down(md) {
          transform: translate(0, 15px);
        }
      }
    }
  }
  .hover {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0px;
    z-index: 2;
    background-color: $gray-600;
    transform: translate(0, 83%);
    transition: 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
    @include media-breakpoint-down(md) {
      position: relative;
      transform: translate(0, 15px);
      padding-bottom: 15px;
      bottom: 15px;
    }
    .curvedsvg {
      position: absolute;
      width: 100%;
      transform: translate(0, -97%);
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        height: 59px;
      }
      @media all and (-ms-high-contrast: none) {
        height: 59px;
      }
    }
    .wrapper {
      h3,
      p,
      a {
        padding-left: 15px;
        padding-right: 15px;
      }
      h3,
      p {
        color: $white;
      }
      p,
      a {
        font-size: 16px;
      }
    }
  }

  .curvedline {
    fill: $white;
  }

  .curved {
    fill: $gray-600;
  }
}
