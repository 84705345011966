.cert-icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 60px;
  img {
    width: 140px;
  }
}

.download-link {
  position: relative;
  text-align: left;
  border: 0;
  background-color: unset;
  padding: 0;
  text-decoration: underline;
  color: #9b0732;
  & > img {
    bottom: 3px;
    right: -30px;
    width: 16px;
    position: absolute;
  }
}
.certificate-download {
  button {
    margin-top: 2rem;
  }
  p {
    margin-bottom: 16px;
  }
}
.certificate-download {
  @include media-breakpoint-down(lg) {
    & > div {
      margin-top: 2rem;
      &:first-child {
        margin-top: 0;
      }
    }
  }
}
