.lazyload,
.lazyloading {
  opacity: 0;
}
.lazyloaded {
  opacity: 1;
  transition: 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  z-index: 2;
  position: relative;
}

@keyframes scaleout {
  0% {
    transform: scale(0); }

  100% {
    transform: scale(1);
    opacity: 0; }
}

.loading {
  position: relative;
 &:before {
    content: "";
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    margin-top: -16px;
    margin-left: -16px;
    width: 32px;
    height: 32px;
    border-radius: 32px;
    animation: scaleout 1.2s infinite ease-in-out;

    // Tweak as needed
    mix-blend-mode: soft-light;
    background-color: rgba(0, 0, 0, .9);
 }
}