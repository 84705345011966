.plus-icon {
  position: absolute;
  left: 0;
  top: 5px;
  span {
    display: block;
  }
  span.circle {
    width: 18px;
    height: 18px;
    border: 1px solid $gray-600;
    border-radius: 50%;
    span {
      width: 8px;
      height: 2px;
      background-color: $gray-600;
      position: absolute;
      top: 8px;
      left: 5px;
      transition: 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
      &:last-of-type {
        transform: rotate(90deg);
      }
    }
  }
}

.dropdown-link.isOpen {
  .plus-icon {
    span.circle {
      span:last-of-type {
        transform: rotate(0deg);
      }
    }
  }
}