.progress-bar {
  margin-bottom: 30px;
  & > div {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .bar {
    height: 10px;
    border-radius: 2px;
    background: $gray-100;
    position: relative;
    padding: 0;
    & > .fill {
      position: relative;
      padding: 0;
      transition: width ease-in-out 0.4s;
      background-color: $primary;
      height: 100%;
    }
  }
  .dots {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    & .dot-col {
      display: flex;
      flex-direction: row;
    }
    & .dot {
      @include media-breakpoint-down(lg) {
        width: 10px;
      }
      &.correct {
        background-color: $blue;
      }
      &.wrong {
        background-color: $red;
      }
      &:first-child {
        margin-left: 0;
      }
      margin-left: 10px;
      height: 5px;
      width: 20px;
      background-color: $gray-100;
      &.filled {
        background-color: $primary;
      }
    }
  }
  .percentage {
    margin-left: -14px;
    transition: left ease-in-out 0.4s;
    top: 150%;
    left: max(100%, 0);
    color: $primary;
    position: absolute;
  }
  .label {
    color: $primary;
    text-align: right;
  }
}
