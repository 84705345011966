.faq {
  padding-bottom: 20px;
  .dropdown-link {
    cursor: pointer;
  }
  position: relative;
  h3 {
    padding-left: 30px;
  }

  .opener {
    transition: height .25s ease;
    overflow: hidden;
    padding-left: 30px;
    &:not(.open) {
      display: none;
    }
  }
}