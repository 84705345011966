.module-page {
  padding-top: $section-padding;
  padding-bottom: $section-padding;
}
.image-text-info {
  margin-top: 2rem;
  color: #535f6b;
}
.module-list {
  li {
    margin-bottom: 0.5rem;
  }
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: 18px;
}
.image-text-text {
  opacity: 0.8;
  margin-top: 0.5rem;
  color: #535f6b;
}
.button-right {
  margin-left: auto;
  display: block;
}
