/* noto-sans-jp-regular - latin */
@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 400;
  src: url('../../src/fonts/noto-sans-jp-v23-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Noto Sans Japanese Regular'), local('NotoSansJapanese-Regular'),
  url('../../src/fonts/noto-sans-jp-v23-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../src/fonts/noto-sans-jp-v23-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../src/fonts/noto-sans-jp-v23-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../../src/fonts/noto-sans-jp-v23-latin-regular.svg#NotoSansJP') format('svg'); /* Legacy iOS */
}
/* noto-sans-jp-700 - latin */
@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 700;
  src: url('../../src/fonts/noto-sans-jp-v23-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Noto Sans Japanese Bold'), local('NotoSansJapanese-Bold'),
  url('../../src/fonts/noto-sans-jp-v23-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../src/fonts/noto-sans-jp-v23-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../src/fonts/noto-sans-jp-v23-latin-700.woff') format('woff'), /* Modern Browsers */
  url('../../src/fonts/noto-sans-jp-v23-latin-700.svg#NotoSansJP') format('svg'); /* Legacy iOS */
}


@font-face {
  font-family: 'outlast_icons';
  src: url('../../src/fonts/outlast_icons.eot?71016634');
  src: url('../../src/fonts/outlast_icons.eot?71016634#iefix') format('embedded-opentype'),
  url('../../src/fonts/outlast_icons.woff2?71016634') format('woff2'),
  url('../../src/fonts/outlast_icons.woff?71016634') format('woff'),
  url('../../src/fonts/outlast_icons.svg?71016634#outlast_icons') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'outlast_icons';
    src: url('../font/outlast_icons.svg?71016634#outlast_icons') format('svg');
  }
}
*/

[class^="icon-"]:before, [class*=" icon-"]:before, [class*=""] {
  font-family: "outlast_icons";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-webfont_dropdown:before { content: '\e800'; } /* '' */
.icon-webfont_globe:before { content: '\e801'; } /* '' */
.icon-webfont_login:before { content: '\e802'; } /* '' */
.icon-webfont_outlast_logo_sw:before { content: '\e803'; } /* '' */
.icon-webfont_icon_facebook:before { content: '\e804'; } /* '' */
.icon-webfont_icon_linkedin:before { content: '\e805'; } /* '' */
.icon-webfont_icon_stumbleupon:before { content: '\e806'; } /* '' */
.icon-webfont_icon_youtube:before { content: '\e807'; } /* '' */
.icon-webfont_contact:before { content: '\e808'; } /* '' */
.icon-webfont_icon_twitter:before { content: '\e809'; } /* '' */
.icon-webfont_icon_instagram:before { content: '\e80a'; } /* '' */
.icon-webfont_icon_vimeo:before { content: '\e80b'; } /* '' */