nav.mobile {
  @include media-breakpoint-up(lg) {
    display: none;
  }
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $secondary;
  z-index: 1000;
  transition: 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate(-100%);
  overflow-y: scroll;
  padding-bottom: 110px;
  padding-top: 110px;
  &.open {
    transform: translate(0%);
  }
  .opener {
    transition: height .25s ease;
    overflow: hidden;
    padding-left: 30px;
    &:not(.open) {
      display: none;
    }
  }
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    li {
      a {
        display: block;
        color: $white;
        cursor: pointer;
        position: relative;
        &:hover, &:active {
          color: $white;
          text-decoration: none;
        }
        i.dropdown-link {
          pointer-events: visible;
          &:after {
            position: absolute;
            right: -12px;
            font-size: 30px;
            content: '\e800';
            font-family: "outlast_icons";
            font-style: normal;
            font-weight: normal;
            speak: none;
            display: inline-block;
            text-decoration: inherit;
            width: 1em;
            margin-right: .2em;
            text-align: center;
            font-variant: normal;
            text-transform: none;
            line-height: 1em;
            margin-left: .2em;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            transform: rotateX(0deg);
            transition: 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
          }
          &.isOpen:after {
            transform: rotateX(180deg);
          }
        }
      }
      &.slim {
        a {
          font-size: 16px;
          color: $gray-500;
          &:hover, &:active {
            color: $gray-500;
          }
          &.dropdown-link {
            &:after {
              font-size: 20px;
              right: -9px;
            }
          }
        }
        ul.level2 {
          li {
            padding-left: 0;
            &::before {
              display: none;
            }
          }
        }
      }
    }
  }
  ul.level1 > li {
    border-bottom: 1px solid $gray-200;
  }
  ul.level1 > li > a {
    font-size: 18px;
    padding: 20px 0;
  }
  ul.level1 > li.slim > a {
    font-size: 16px;
    padding: 15px 0;
    color: $gray-500;
  }
  ul.level1 > li.active > a {
    font-weight: 700;
  }
  ul.level1 > li:first-of-type > a {
    border-top: 1px solid $gray-200;
  }
  ul.level2 {
    overflow: hidden;
    li {
      position: relative;
      padding-left: 20px;
      &.active {
        font-weight: 700;
        &::before {
          background-color: $gray-200;
        }
      }
      &::before {
        transition: 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
        position: absolute;
        width: 11px;
        height: 11px;
        background-color: transparent;
        border: 1px solid $gray-200;
        border-radius: 50%;
        top: 23px;
        left: 0;
        content: "";
      }
      &:last-of-type {
        padding-bottom: 20px;
      }
      a {
        font-size: 16px;
        padding: 15px 0;
      }
    }
  }
}

body.open {
  overflow: hidden;
}

.mobile-nav-bounce-fixer {
  position: absolute;
  z-index: 999;
  width: 100%;
  height: 100%;
  background-color: $secondary;
  display: none;
  &.open {
    display: block;
  }
}

header {
  .mobile {
    @include media-breakpoint-up(lg) {
      display: none;
    }
    background-color: $gray-100;
    position: relative;
    .logo {
      position: absolute;
      right: 15px;
      top: 10px;
      z-index: 1002;
      width: 80px;
    }
  }
}
