section.contact {
  form {
    .hidden {
      display: none;
    }
    .error {
      color: $red;
    }
    .success {
      color: $green;
    }
    button {
      min-width: 300px;
    }
    /* form starting stylings ------------------------------- */
    .group 			  {
      position:relative;
      margin-bottom:30px;
    }
    textarea {
      background-color: $white;
      width: 100%;
      caret-color: $gray-600;
      color: $gray-600;
      border:none;
      border:1px solid $gray-400;
      border-radius: 5px;
      padding: 5px;
      min-height: 100px;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus {
      -webkit-text-fill-color: $gray-600;
      -webkit-box-shadow: 0 0 0px 1000px $white inset;
      transition: background-color 5000s ease-in-out 0s;
    }
    input.btn-primary {
      border: 0;
      width: 250px;
      margin-top: 35px;
      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }
    input[type='text'],
    input[type='email'],
    input[type='number'],
    input[type='password'],
    input[type='url'],
    input[type='tel']
    {
      font-size:18px;
      font-weight: 500;
      padding: 12px;
      display:block;
      width:100%;
      border: 1px solid $gray-400;
      background-color: $white;
      caret-color: $gray-600;
      color: $gray-600;
      border-radius: 5px;
      transition:0.2s ease all;
    }
    input:focus, textarea:focus 		{ outline:none; }

    /* LABEL ======================================= */
    label 				 {
      color: $gray-600;
      font-size:18px;
      font-weight: 500;
      position:absolute;
      pointer-events:none;
      left:10px;
      top:12px;
      background-color: transparent;
      transition:0.2s ease all;
      -moz-transition:0.2s ease all;
      -webkit-transition:0.2s ease all;
      padding-right: 0.25rem;
      padding-left: 0.25rem;
    }

    /* active state */
    input[type='text']:focus ~ label,
    input[type='email']:focus ~ label,
    input[type='number']:focus ~ label,
    input[type='password']:focus ~ label,
    input[type='url']:focus ~ label,
    input[type='tel']:focus ~ label,
    input[type='text'].not-empty ~ label,
    input[type='email'].not-empty ~ label,
    input[type='number'].not-empty ~ label,
    input[type='password'].not-empty ~ label,
    input[type='url'].not-empty ~ label,
    input[type='tel'].not-empty ~ label,
    input[type='tel'].not-empty ~ label
    {
      top:-12px;
      left: 9px;
      font-size:14px;
      font-weight: 300;
      color:$secondary;
      background-color: $white;
    }
    input[type='text'].not-empty,
    input[type='email'].not-empty,
    input[type='number'].not-empty,
    input[type='password'].not-empty,
    input[type='url'].not-empty,
    input[type='tel'].not-empty,
    textarea.not-empty
    {
      border: 1px solid $secondary;
    }
    input[type='text'].not-empty:invalid ~ label,
    input[type='email'].not-empty:invalid ~ label,
    input[type='number'].not-empty:invalid ~ label,
    input[type='password'].not-empty:invalid ~ label,
    input[type='url'].not-empty:invalid ~ label,
    input[type='tel'].not-empty:invalid ~ label,
    input[type='text']:focus:invalid ~ label,
    input[type='email']:focus:invalid ~ label,
    input[type='number']:focus:invalid ~ label,
    input[type='password']:focus:invalid ~ label,
    input[type='url']:focus:invalid ~ label,
    input[type='tel']:focus:invalid ~ label,
    textarea:focus:invalid ~ label
    {
      color:$red !important;
    }

    textarea:focus ~ label,
    textarea.not-empty ~ label {
      top:-12px;
      left:0;
      font-size:14px;
      color:$secondary;
      background-color: $white;
      font-weight: 300;
    }
    .textarea {
      .hidden-left {
        width: 40px;
      }
      .hidden-right {
        height: 40px;
      }
    }

    /* BOTTOM BARS ================================= */
    .bar 	{ position:relative; display:block; width:100%; }
    .bar:before, .bar:after 	{
      content:'';
      height:2px;
      width:0;
      bottom:0;
      position:absolute;
      background:$secondary;
      transition:0.2s ease all;
      -moz-transition:0.2s ease all;
      -webkit-transition:0.2s ease all;
    }
    .bar:before {
      left:50%;
    }
    .bar:after {
      right:50%;
    }
    .textarea {
      .bar:before, .bar:after 	{
        bottom: 8px;
      }
      @-moz-document url-prefix() {
        .bar:before, .bar:after 	{
          bottom: 0;
        }
      }
      @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
        .bar:before, .bar:after 	{
          bottom: 0;
        }
      }
      @supports (-ms-ime-align:auto) {
        .bar:before, .bar:after 	{
          bottom: 0;
        }
      }
    }

    /* active state */
    input:focus ~ .bar:before, input:focus ~ .bar:after {
      width:50%;
    }
    input:invalid:focus, textarea:invalid:focus {
      border: 1px solid $red;
    }
    textarea:focus ~ .bar:before, textarea:focus ~ .bar:after {
      width:50%;
    }

    /* HIGHLIGHTER ================================== */
    .highlight {
      position:absolute;
      height:60%;
      width:100px;
      top:25%;
      left:0;
      pointer-events:none;
      opacity:0.5;
    }

    .textarea {
      .highlight {
        top:0;
      }
    }

    /* active state */
    input:focus ~ .highlight, textarea:focus ~ .highlight {
      -webkit-animation:inputHighlighter 0.3s ease;
      -moz-animation:inputHighlighter 0.3s ease;
      animation:inputHighlighter 0.3s ease;
    }

    /* ANIMATIONS ================ */
    @-webkit-keyframes inputHighlighter {
      from { background:$secondary; }
      to 	{ width:0; background:$secondary; }
    }
    @-moz-keyframes inputHighlighter {
      from { background:$secondary; }
      to 	{ width:0; background:$secondary; }
    }
    @keyframes inputHighlighter {
      from { background:$secondary; }
      to 	{ width:0; background:$secondary; }
    }




    // Checkbox and Radioxbox
    .form-radio {
      position: relative;
      margin-bottom: 2.25rem;
      display: flex;
      @include media-breakpoint-down(sm) {
        display: block;
      }
    }

    .checkbox label,
    .form-radio label {
      position: relative;
      cursor: pointer;
      padding-left: 2rem;
      padding-right: 2rem;
      text-align: left;
      color: $gray-600;
      display: block;
      pointer-events: all;
      font-weight: 300;
      top: 0;
      left: 0;
    }

    .checkbox input,
    .form-radio input {
      width: auto;
      opacity: 0.00000001;
      position: absolute;
      left: 0;
    }

    .radio {
      margin-bottom: 1rem;
    }

    .radio .helper {
      position: absolute;
      top: -0.25rem;
      left: -0.25rem;
      cursor: pointer;
      display: block;
      font-size: 1rem;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      color: $secondary;
    }

    .radio .helper::before, .radio .helper::after {
      content: '';
      position: absolute;
      left: 0;
      top: 5px;
      margin: 0.25rem;
      width: 1rem;
      height: 1rem;
      -webkit-transition: -webkit-transform 0.28s ease;
      transition: -webkit-transform 0.28s ease;
      transition: transform 0.28s ease;
      transition: transform 0.28s ease, -webkit-transform 0.28s ease;
      border-radius: 50%;
      border: 0.125rem solid currentColor;
    }

    .radio .helper::after {
      -webkit-transform: scale(0);
      transform: scale(0);
      background-color: $secondary;
      border-color: $secondary;
    }

    .radio label:hover .helper {
      color: $secondary;
    }

    .radio input:checked ~ .helper::after {
      -webkit-transform: scale(0.5);
      transform: scale(0.5);
    }

    .radio input:checked ~ .helper::before {
      color: $secondary;
    }

    /*Checkbox style*/
    .checkbox .helper {
      color: $secondary;
      position: absolute;
      top: 5px;
      left: 0;
      width: 1rem;
      height: 1rem;
      z-index: 0;
      border: 0.125rem solid $secondary;
      border-radius: 0.0625rem;
      -webkit-transition: border-color 0.28s ease;
      transition: border-color 0.28s ease;
    }

    .checkbox .helper::before, .checkbox .helper::after {
      position: absolute;
      height: 0;
      width: 0.2rem;
      background-color: $secondary;
      display: block;
      -webkit-transform-origin: left top;
      transform-origin: left top;
      border-radius: 0.25rem;
      content: '';
      -webkit-transition: opacity 0.28s ease, height 0s linear 0.28s;
      transition: opacity 0.28s ease, height 0s linear 0.28s;
      opacity: 0;
    }

    .checkbox .helper::before {
      top: 0.65rem;
      left: 0.38rem;
      -webkit-transform: rotate(-135deg);
      transform: rotate(-135deg);
      //box-shadow: 0 0 0 0.0625rem $secondary;
    }

    .checkbox .helper::after {
      top: 0.3rem;
      left: 0;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
    .checkbox label:hover .helper {
      color: $secondary;
      border: 0.125rem solid $secondary;
    }
    .checkbox input:checked ~ .helper {
      color: $secondary;
      border: 0.125rem solid $secondary;
    }
    .checkbox input:checked ~ .helper::after, .checkbox input:checked ~ .helper::before {
      opacity: 1;
      -webkit-transition: height 0.28s ease;
      transition: height 0.28s ease;
    }
    .checkbox input:checked ~ .helper::after {
      height: 0.5rem;
    }

    .checkbox input:checked ~ .helper::before {
      height: 1.2rem;
      -webkit-transition-delay: 0.28s;
      transition-delay: 0.28s;
    }

    .radio + .radio,
    .checkbox + .checkbox {
      // margin-top: 1rem;
    }
    .text {
      margin-bottom: 15px;
    }
  }
}
