.img-text-grid {
  img {
    margin-bottom: 1.25rem;
    border: 1px solid $light-blue;
    border-radius: $border-radius;
  }
  .row {
    margin-bottom: 1.25rem;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  @include media-breakpoint-down(md) {
    a.btn {
      display: block;
      width: 100%;
    }
  }
}


