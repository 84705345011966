.fade-enter {
  opacity: 0;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 450ms ease-out;
}
.fade-exit {
  opacity: 0;
}
.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 450ms ease-out;
}

.page-fade-enter {
  opacity: 0.4;
}
.page-fade-enter.page-fade-enter-active {
  opacity: 1;
  transition: opacity 400ms ease-out;
}
.page-fade-enter-done {
  opacity: 1;
}
.page-fade-exit {
  opacity: 0;
}
.page-fade-exit.page-fade-exit-active {
  opacity: 0;
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
}
.page-fade-exit-done {
  opacity: 0;
}

@media (min-width: 992px) {
  .box:hover{
    & li {
      margin-bottom: 4px;
    }
  }
}

.lang-nav{
  align-self: center;
  font-family: "Noto Sans JP", "Helvetica Neue", Arial, "Noto Sans";
  font-size: 11px;
  font-weight: 400;
  // text-decoration: underline;
  color: rgb(161, 167, 174);
  cursor: pointer;
}

.lang-nav.mobile {
  font-size: 16px;
  padding: 15px 0px;
}

.container-large {
  max-width: 1140px;
  @include media-breakpoint-down(lg) {
    max-width: 960px;
  }
}

.no-padding-top {
  padding-top: 0px !important;
}
.no-padding-bottom {
  padding-bottom: 0px !important;
}

div.image-text-image {
  @include media-breakpoint-down(md) {
    padding-bottom: 30px;
  }
}

.object-fit-scalable {
  object-fit: contain;
}

.bg-white {
  background-color: #fff;
}

.outline-button-link {
  color: #fff;
  margin: 0px 20px;
  margin-top: 50px;
  padding: 6px 20px;
  border: solid 1px #fff;
  border-radius: 0.25rem;
}