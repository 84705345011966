section {
  padding-top: $section-padding;
  padding-bottom: $section-padding;
  position: relative;
  &.size-small {
    padding-top: $section-padding-small;
    padding-bottom: $section-padding-small;
  }
  &.gray {
    background-color: $gray-100;
    .button {
      background-color: $gray-100;
    }
  }
  &.img-grid {
    padding: 0;
  }
  &.blue {
    background-color: #6b8faa;
    h1,
    h2,
    h3,
    p {
      color: $white;
    }
    .loading:before {
      background-color: rgba(255, 255, 255, 0.9);
    }
    .button {
      background-color: #6b8faa;
      color: $white;
      border-color: $white;
      &:hover {
        border-color: transparent;
        color: $white;
      }
    }
    .applications-grid {
      .box {
        border-color: $white;
      }
    }
    .img-text-grid {
      img {
        border-color: $white;
      }
    }
    .plus-icon {
      span.circle {
        border-color: $white;
        span {
          background-color: $white;
        }
      }
    }
    hr {
      border-color: $white;
    }
    .download {
      .dropdown-link {
        p {
          color: $white;
        }
      }
      .box {
        border-color: $white;
      }
      img {
        border-color: $white;
      }
    }
  }
  &.p0 {
    padding: 0;
  }
}
img.border {
  border: 1px solid #535f6b;
  border-radius: 4px;
}
