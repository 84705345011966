h1 {
  text-transform: uppercase;
  @include media-breakpoint-down(md) {
    font-size: 1.375rem;
  }
}

h2 {
  @include media-breakpoint-down(md) {
    font-size: 1.375rem;
  }
}

h3 {
  @include media-breakpoint-down(md) {
    font-size: 1.25rem;
  }
}

.h1 {
  font-size: $h1-font-size;
  text-transform: uppercase;
  @include media-breakpoint-down(md) {
    font-size: 1.375rem;
  }
}

p {
  font-size:1.125rem;
}

.text-center {
  text-align: center;
}