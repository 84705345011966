.enduses-grid {
  img {
    width: 100%;
  }
  .col-12 {
    padding: 15px;
  }
  a.btn {
    min-width: auto;
  }
}