.question {
  & button {
    text-align: left;
  }
  h2 {
    margin-bottom: 3rem;
  }
  margin-bottom: 60px;
  .answer > button {
    box-sizing: border-box;
    padding: 20px;
    width: 100%;
    background: none;
    border: 1px solid;
    border-color: $gray-600;
    color: $gray-600;
    border-radius: 5px;
    margin-bottom: 30px;
    transition: all ease-in-out 0.2s;
    &:hover {
      background-color: #f0f0f1;
    }
  }
  .answer.correct > button {
    background-color: $blue;
    color: #fff;
    border: none;
  }
  .answer.wrong > button {
    background-color: $red;
    color: #fff;
    border: none;
  }
  .result {
    padding: 40px 20px;
    padding-right: 20px;
    background-color: $gray-100;
    & > p {
      margin: 0;
      padding-left: 16px;
      position: relative;
    }
    &.correct {
      span {
        font-weight: bold;
        color: $blue;
        &.border {
          background-color: $blue;
        }
      }
    }
    &.wrong {
      span {
        font-weight: bold;
        color: $red;
        &.border {
          background-color: $red;
        }
      }
    }
    .border {
      left: 0;
      position: absolute;
      display: block;
      height: 100%;
      width: 3px;
    }
  }
}
