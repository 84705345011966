.bottom-line {
  position: absolute;
  left: 15px;
  bottom: -$section-padding;
  width: 150px;
  height: 2px;
  background-color: $primary;
}
.top-line {
  position: absolute;
  left: 15px;
  top: -$section-padding;
  width: 150px;
  height: 2px;
  background-color: $primary;
}
section.size-small {
  .top-line {
    top: -$section-padding-small;
  }
  .bottom-line {
    bottom: -$section-padding-small;
  }
}
