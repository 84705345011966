.bold {
  font-weight: bold;
}
.p0 {
  padding: 0;
}
.m0 {
  margin: 0;
}
.pt0 {
  padding-top: 0;
}
.side-by-side.row .col-lg-6 {
  @include media-breakpoint-down(lg) {
    margin-top: 1rem;
    &:first-child {
      margin-top: 0;
    }
  }
}
