$nav-desktop-close-height: 69px;
$nav-desktop-open-height: 450px;

nav.desktop {
  background-color: $gray-100;
  transition: background-color 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  position: absolute;
  width: 100%;
  z-index: 10;
  top: 0;
  @include media-breakpoint-down(md) {
    display: none;
  }
  .row {
    padding-right: 150px;
  }
  .activebar {
    position: absolute;
    width: 0;
    height: 2px;
    background-color: $primary;
    left: 15px;
    top: 67px;
    pointer-events: none;
    &.on {
      transition: 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
    }
  }
  ul.level1 {
    display: flex;
    // justify-content: space-between;
    margin-bottom: 0;
    color: #535f6b;
    font-size: 18px;
    padding: 20px 0;
    width: 100%;
    height: $nav-desktop-close-height;
    overflow: hidden;
    transition: 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
    position: relative;
    & > li {
      &:first-child {
        margin-left: 0;
      }
      margin-left: 80px;
      text-align: center;
    }
    li {
      list-style: none;
      a {
        padding: 20px 0;
        color: #535f6b;
        &:hover {
          color: #535f6b;
          text-decoration: none;
        }
      }
    }
  }
  ul.level1 > li.active > a {
    border-bottom: 2px solid $primary;
  }
  ul.level1.loaded > li.active > a {
    border-bottom: none;
  }
  ul.level2 {
    padding-top: 40px;
    padding-left: 30px;
    li {
      padding: 5px 0;
      height: auto;
      position: relative;
      &.active {
        font-weight: 700;
        &::before {
          background-color: $gray-100;
        }
      }
      a {
        padding: 5px 0;
      }
      &::before {
        transition: 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
        position: absolute;
        width: 11px;
        height: 11px;
        background-color: transparent;
        border: 1px solid $gray-100;
        border-radius: 50%;
        top: 14px;
        left: -20px;
        content: "";
      }
      &:hover {
        &::before {
          background-color: $gray-100;
        }
      }
    }
  }
  .logo {
    transition: 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
    position: absolute;
    right: -135px;
    top: 0;
    width: 109px;
  }
  &.follow {
    top: 0;
    position: fixed;
    .logo {
      width: 87px;
      top: 16px;
    }
  }
  &.open {
    background-color: $secondary;
    ul.level1 {
      height: $nav-desktop-open-height;
      color: $white;
      li {
        a {
          color: $gray-100;
          &:hover {
            color: $gray-100;
          }
        }
      }
    }
    ul.level1 > li.active > a {
      border-bottom: 2px solid $gray-100;
    }
    ul.level1.loaded > li.active > a {
      border-bottom: none;
    }
    .activebar {
      background-color: $gray-100;
    }
  }
}
