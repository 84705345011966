.button {
  display: inline-block;
  transition: 0.25s ease-in-out;
  text-align: center;
  vertical-align: middle;
  background-color: $white;
  user-select: none;
  border: 1px solid $primary;
  padding: .65rem 1.25rem;
  font-size: 1.125rem;
  line-height: 1.5;
  border-radius: .25rem;
  margin-bottom: 1.25rem;
  min-width: 250px;
  color: $primary;
  @include media-breakpoint-down(xs) {
    width: 100%;
  }
  &:hover {
    color: $primary;
    border-color: transparent;
    background-color: transparent;
    box-shadow: $primary 7px -3px 0 0, #3B78AF -7px 3px 0 0;
    text-decoration: none;
  }
  &:active {
    color: $primary;
    border-color: transparent;
    background-color: transparent;
  }
  &:focus:not(:active) {
    animation: buttonanimation 0.4s ease-in-out;
  }
  &.button-darkblue {
    border: 1px solid $gray-800;
    color: $gray-800;
    &:hover {
      color: $gray-800;
      border-color: transparent;
    }
  }
  &:focus {
    outline: none;
  }

  @keyframes buttonanimation {
    0% {
      box-shadow: $primary 7px -3px 0 0, #3B78AF -7px 3px 0 0;
      border-color: transparent;
      background-color: transparent;
    }
    100% {
      box-shadow: transparent 25px -3px 0 0, transparent -25px 3px 0 0;
      border-color: transparent;
      background-color: transparent;
    }
  }

  &.disabled {
    pointer-events: none;
    cursor: default;
    border-color: $gray-500;
    color: $gray-500
  }
}

.btn-block {
  min-width: auto !important;
  display: block !important;
  width: 100%;
}
