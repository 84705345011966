nav.login {
  background-color: $gray-100;
  transition: 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  .row {
    padding-right: 150px;
  }
  @include media-breakpoint-down(md) {
    display: none;
  }
  ul {
    display: flex;
    margin-bottom: 0;
    justify-content: flex-end;
    color: $gray-500;
    font-size: 11px;
    border-bottom: 1px solid #c1c4c9;
    padding: 10px 0;
    width: 100%;
    li {
      list-style: none;
      a {
        padding: 10px 9px 9px 9px;
        color: $gray-500;
        position: relative;
        &:hover {
          color: $gray-500;
          text-decoration: none;
        }
        &.dropdown-toggle {
          padding-left: 27px;
          .icon-webfont_globe {
            left: 2px;
            top: 6px;
          }
          .icon-webfont_dropdown {
            right: -9px;
            left: auto;
          }
        }
        i {
          font-size: 16px;
          position: absolute;
          left: -12px;
          top: 5px;
        }
      }
      &:not(.dropdown) {
        padding-left: 12px;
      }
      .icon-webfont_contact {
        left: -15px;
      }
    }
  }
  &.open {
    background-color: $secondary;
    .dropdown-toggle {
      background-color: $secondary;
    }
  }
}