.hamburger {
  width: 60px;
  height: 60px;
  position: relative;
  left: -12px;
  cursor: pointer;
  z-index: 1002;
  span {
    width: 26px;
    height: 4px;
    background-color: $hamburger;
    display: block;
    position: absolute;
    transition: 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate(0);
  }
  span:nth-of-type(1) {
    top: 20px;
    left: 12px;
  }
  span:nth-of-type(2) {
    top: 28px;
    left: 12px;
  }
  span:nth-of-type(3) {
    top: 36px;
    left: 12px;
  }
  &.open {
    span {
      background-color: $white;
    }
    span:nth-of-type(1) {
      transform: rotate(-45deg);
    }
    span:nth-of-type(2) {
      transform: translate(8px);
    }
    span:nth-of-type(3) {
      transform: rotate(45deg);
    }
  }
}